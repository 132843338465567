<template>
    <b-modal id="mess-secretary-create-modal" title="Mess Secretary Create Modal" hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
      <template #modal-title>
        <h2 class="m-0">Create Mess Secretary</h2>
      </template>
      <template v-if="isBusy">
        <div class="text-center text-danger my-2">
          <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-else>
        <validation-observer ref="messSecretaryCreateFormValidation">
          <b-form @submit.prevent>
            <b-row>
              <b-col>
                <validation-provider #default="{ errors }" name="Secretary" rules="required">
                <b-form-group label-for="secretary" :state="errors.length > 0 ? false : null">
                    <template #label>
                    Mess Secretary <span class="text-danger">*</span>
                    </template>
                    <b-row>
                        <b-col>
                            <v-select id="secretary" inputId="id" label="name" v-model="selectedSecretary"
                            :options="secretary" placeholder="Mess Secretary" class="v-style-chooser" />
                        </b-col>
                    </b-row>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
            <b-col>
              <validation-provider #default="{ errors }" name="Messes" rules="required">
                <b-form-group label-for="messes" :state="errors.length > 0 ? false : null">
                  <template #label>
                    Mess Details <span class="text-danger">*</span>
                  </template>
                  <b-row>
                    <b-col>
                      <v-select id="messes" inputId="id" label="name" v-model="selectedMesses"
                        :options="messes" placeholder="Mess" class="v-style-chooser" />
                    </b-col>
                  </b-row>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
    
            <b-form-group class="text-right">
              <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
                Create
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
  </template>
  
  <script>
  import Vue from "vue";
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import VuePerfectScrollbar from "vue-perfect-scrollbar";
  import draggable from "vuedraggable";
  import util from "@/util.js";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VuePerfectScrollbar,
      draggable,
    },
    mixins: [util],
    props: ["showModal"],
    data() {
      return {
        perfectScrollbarSettings: {
          maxScrollbarLength: 150,
          wheelPropagation: false,
          useBothWheelAxes: false,
          suppressScrollX: true,
        },
        selectedSecretary: null,
        secretary: [],
        selectedMesses: null,
        messes: [],
        isBusy: true,
      };
    },
    async mounted() {
    if (this.showModal) {
      try {

        this.isBusy = true

        let res = await this.getMessesUnpaginated({
          not_assigned: true,
        })
        this.messes = res.data

        res = await this.getUsersUnPaginated({
          is_officer: true,
          no_mess_secretaries: true,
          no_clerks: true,
          exclude_user: this.getLoggedInUser.id,
          only_booking_users: true
        })
        this.secretary = res.data

        this.isBusy = false

      } catch (error) {
        this.displayError(error);
      }
    }
  },
  methods: {
     ...mapActions({
        createMessSecretary: "appData/createMessSecretary",
        getMessesUnpaginated: "appData/getMessesUnpaginated",
        getUsersUnPaginated: "appData/getUsersUnPaginated",
      }),
    async validationForm() {
      const success = await this.$refs.messSecretaryCreateFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      try {
        let formData = new FormData();

        let dataToInsert = {
          user: this.selectedSecretary.id,
          mess: this.selectedMesses.id
        };
        formData.append("data", JSON.stringify(dataToInsert));

        const res = await this.createMessSecretary(formData);
        if (res.status === 201) {
          this.$swal({
            title: "Mess Secretary created successfully",
            icon: "success",
          });
          this.$nextTick(() => {
            this.$bvModal.hide("mess-secretary-create-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
          this.show = false;
          if (error.response.status == 400) {
            // this.displayError("Kindly make sure this user/mess does not have any mess/secretary assigned to it");
            this.$swal({
              title: "Kindly make sure this user/mess does not have any mess/secretary assigned to it",
              icon: "error",
            });
          }
          else {
            this.displayError(error);
          }
      }
    },
      reset() { },
      resetModal() {
      },
  },
  computed: {
      ...mapGetters({ getLoggedInUser: "appData/getUser" }),
    },
    watch: {
    },  
};
</script>

<style lang="scss" scoped>
</style>
